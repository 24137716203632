<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
    >
      <van-sticky :offset-top="0">
        <van-tabs
          v-model="activeTab"
          calss="tab-out-box"
          swipeable
          color="#51B464"
          title-inactive-color="#262626"
          title-active-color="#51B464"
          @change="tabChange"
        >
          <div class="border-x"></div>
          <template v-for="(item,index) in classifyList">
            <van-tab
              :key="index"
              class="van-tab-box"
              :name="'tab' + index"
              :title="item.classifyName"
            >
            </van-tab>
          </template>
        </van-tabs>
      </van-sticky>

      <div class="type-box box">
        <div class="banner-box">
          <img
            :src="require('@/assets/img/type-banner.png')"
            alt=""
          >
        </div>

        <van-dropdown-menu :active-color="'#51B464'">
          <van-dropdown-item
            v-model="oldValue"
            :options="option1"
            @change="oldChange"
          />
          <van-dropdown-item
            v-model="value2"
            :options="option2"
            @change="distanceChange"
          />
        </van-dropdown-menu>
      </div>
      <!-- 列表 -->
      <div
        class="box"
        style="padding: 0 15px;"
      >
        <template v-for="item in schoolList">
          <div
            :key="item.schoolNo"
            class="box"
            @click="goSchoolDetail(item)"
          >
            <list-item :item="item"></list-item>
          </div>
        </template>
        <div
          v-show="loading"
          class="end-text box"
        >
          加载中...
        </div>
        <div
          v-show="finished"
          class="end-text box"
        >
          没有更多了
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import ListItem from "@/components/list-item/school-item.vue"
export default {
  name: 'OrderIndex',
  components: {
    ListItem
  },
  data () {
    return {
      activeTab: '2a',
      oldValue: '0',
      classifyNo: '',
      option1: [
        { text: '年龄：全部 ', value: '0' },
        { text: '年龄：1-5 ', value: '1' },
        { text: '年龄：6-10 ', value: '2' },
        { text: '年龄：11-15 ', value: '3' },
        { text: '年龄：16-20 ', value: '4' },
        { text: '年龄：20以上 ', value: '5' },
      ],
      oldObj: {
        '1': ['1', '5'],
        '2': ['6', '10'],
        '3': ['11', '15'],
        '4': ['16', '20']
      },
      value2: '0',
      option2: [
        { text: '距离：最近 ', value: '0' },
        { text: '距离：2km ', value: '1' },
        { text: '距离：3km ', value: '2' },
        { text: '距离：5km ', value: '3' },
      ],
      tabList: [
        {name: '全部', key: 'all'},
        {name: '审核中', key: 'audit'},
        {name: '已结清', key: 'ok'},
        {name: '已取消', key: 'cancel'},
        {name: '等待放款', key: 'wait'},
      ],
      schoolList: [],
      isLoading: false,
      loading: false,
      total: 0,
      finished: false,
      pageNo: 1,
      pageSize: 10
    }
  },
  computed: {
  },
  created () {
    console.log(this.$route.query.index, 'index')
    let list = JSON.parse(window.localStorage.getItem('homeClassifyList')) || []
    this.classifyList = list
    // this.$nextTick(() => {
      
    // })
    let index = this.$route.query.index || 0
    this.classifyNo = this.$route.query.classifyNo
    if (index < 7) {
      this.activeTab = 'tab' + index
    }
    let vm = this
    try {
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res) {
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          window.localStorage.setItem('latitude', latitude)
          window.localStorage.setItem('longitude', longitude)
        },
        complete () {
          vm.getSchoolList()
        }
      });
    } catch (e) {
      console.log(e)
      vm.getSchoolList()
    }
  },
  methods: {
    tabChange (e) {
      let str = parseInt(e.replace('tab', ''))
      this.classifyNo = this.classifyList[str].classifyNo
      this.loadList()
    },
    loadList () {
      this.pageNo = 1
      this.schoolList = []
      this.getSchoolList()
    },
    async getSchoolList () {
      let vm = this
      if (!this.loading) {
        this.loading = true;
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          classifyNo: this.classifyNo,
          lat: window.localStorage.getItem('latitude') || '',
          lng: window.localStorage.getItem('longitude') || ''
        }
        if (this.oldValue !== '0') {
          if (this.oldValue === '5') {
            data.startOld = 20
            data.endAge = 99
          } else {
            data.startAge = this.oldObj[this.oldValue][0]
            data.endAge = this.oldObj[this.oldValue][1]
          }
        }
        this.pageNo ++
        try {
          let res = await this.$http.homeApi.schoolList(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.schoolList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.schoolList = arr
            this.total = res.msg.total
            console.log(this.schoolList.length, res.msg.total)
            this.finished = this.schoolList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    async onRefresh () {
      this.schoolList = []
      this.pageNo = 1
      await this.getSchoolList()
      this.isLoading = false
    },
    // 处理滚动条滚到底部加载数据
    getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        if ((this.pageNo -1) * this.pageSize <= this.total) {
          this.getSchoolList()
        }
      }
    },
    oldChange (e) {
      this.loadList()
    },
    distanceChange (e) {
      console.log(e, '1')
    },
    goSchoolDetail (item) {
      this.$router.push(`/school/detail?id=${item.schoolNo || ''}`)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  /deep/.van-tabs {
    border-bottom: 1px solid #d9d9d9;
  }
  .tab-out-box {
    /deep/ .van-tab__text {
      font-size:15px;
    }
  }
  .van-tab-box {
    position: absolute;
    left: -2000px;
    top: 0;
  }
  .type-box {
    .banner-box {
      padding: 10px 15px;
      width: 100%;
      height: 85px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
